@font-face {
    font-family: "Altdeutsch";
    src: url("/css/fonts/FetteUNZFraktur.ttf") format("truetype");
}
@import url(https://fonts.googleapis.com/css?family=Nobile);
@import url(https://fonts.googleapis.com/css?family=Pontano+Sans);
$font-family-sans-serif: 'Pontano Sans', Nobile;
$grid-float-breakpoint:992px;
$body-bg: #fdf4f1;
$navbar-default-bg: #b70b24;
$navbar-default-color: white;
$navbar-default-link-color: $navbar-default-color;
$navbar-default-link-active-color: $navbar-default-color;
$navbar-default-link-hover-color: lighten(#000, 33.5%);
$brand-primary: $navbar-default-bg;

$icon-font-path: './fonts/bootstrap/';

@function font-path($path) {
  @return $path;
}
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
$eckenRadius:10px;

html, body {
  height: 100%;
}

#wrap {
  min-height: 100%;
}

#main {
  overflow:auto;
  padding-bottom:50px; /* this needs to be bigger than footer height*/
  padding-top:50px;
}
#start {
  overflow:auto;
  padding-bottom:70px; /* this needs to be bigger than footer height*/
  padding-top:50px;
}

footer {
    position: relative;
    margin-top: -50px; /* negative value of footer height */
    height: 50px;
    clear: both;
    background-color: #b70b24;
    padding-top: 0px;
    color: white;
    a{
        color: white;
        &:hover {
            color: lighten(#000, 33.5%);
        }
    }
}

.navbar-default .navbar-toggle .icon-bar{
    background-color: white;
}
.navbar{
    img{
        height: $navbar-height;
    }
}
/*full width container*/
@media (max-width: 767px) {
    .block {
        margin-left: -20px;
        margin-right: -20px;
    }
}
.infowdow{
    line-height:1.35;
    overflow:hidden;
    white-space:nowrap;
    font-size: 12px;
}
/*Body*/
body {
    background-color: $body-bg;
}
.startseite{
    .glyphicon{
        font-size: 30px;
        .baer{
            vertical-align: top;
        }
    }
    #inlineDiv{
        display:inline-block;
    }
    hr.rot{
        border: 0;
        height: 1px;
        background-image: -webkit-linear-gradient(left, rgba(183,11,36,0), rgba(183,11,36,0.75), rgba(183,11,36,0));
        background-image:    -moz-linear-gradient(left, rgba(183,11,36,0), rgba(183,11,36,0.75), rgba(183,11,36,0));
        background-image:     -ms-linear-gradient(left, rgba(183,11,36,0), rgba(183,11,36,0.75), rgba(183,11,36,0));
        background-image:      -o-linear-gradient(left, rgba(183,11,36,0), rgba(183,11,36,0.75), rgba(183,11,36,0));
    }
    #jumbo1{
        h1{
            letter-spacing: -2px;
            font-size: 60px;
            font-family: "Altdeutsch";
        }
        background: transparent;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
    small{
        color: inherit;
    }
    .glyphicon{
        color: lighten(#000, 20%);
    }
    img{
        display: inline-block;
    }
    .img-wrapper{
        margin: 0 auto;
        .caption{
          font-size: 16px;
          display: block;
          width:100%;
          background-color: rgb(255,200,87);
        }
    }
    .btn{
        margin-bottom: 10px;
    }
    //holidaycheck widget
    .hc-widget{
      width: auto !important;
    }
}
/*Footer*/
@media (min-width: 0px) {
    footer {
      div{
        background-color: #b70b24;
      }
        .navbar-left {
            float: left;
        }
        .navbar-right {
            float: right;
        }
    }
}
#wrap {
  min-height: 100%;
}
/*Carousel*/
// .carousel-inner > .item > img, .carousel-inner > .item > a > img {
//     margin: auto;
//     background-color: white;
// }
// #carouselWrapper {
//     img {
//         border-radius: $eckenRadius;
//     }
// }
// .carousel-control {
//     border-radius: $eckenRadius;
// }
// #myCarousel{
//     margin-bottom: $eckenRadius;
// }
// // alter Fade hat nicht richtig funktioniert, deswegen untere Zeilen eingefügt.
// /*
// .carousel .item {
//     -webkit-transition: opacity 2s;
//     -moz-transition: opacity 2s;
//     -ms-transition: opacity 2s;
//     -o-transition: opacity 2s;
//     transition: opacity 2s;
// }
// .carousel .active.left {
//     left:0;
//     opacity:0;
//     z-index:2;
// }
// .carousel .next {
//     left:0;
//     opacity:1;
//     z-index:1;
// }
// */
// //neuer Fade
// .carousel-inner {
//   .item {
//     opacity: 0;
//     transition-property: opacity;
//   }

//   .active {
//     opacity: 1;
//   }

//   .active.left,
//   .active.right {
//     left: 0;
//     opacity: 0;
//     z-index: 1;
//   }

//   .next.left,
//   .prev.right {
//     opacity: 1;
//   }
// }
// .carousel-control {
//   z-index: 2;
// }
// .carousel-control.right{
//     background-image: none;
// }
// .carousel-control.left{
//     background-image:none;
// }

body .galleria-container{
  background: #fdf4f1;
}
body .galleria-theme-twelve .galleria-stage{
  background-color: #fdf4f1;
}
body .galleria-theme-twelve .galleria-bar{
  background-color: #b70b24;
}
.galleria{
  margin-bottom: 30px;
}
.galleria-thumbnails{
  margin-left: auto;
  margin-right: auto;
}
.galleria-lightbox-content{
  background: #fdf4f1;
}
.galleria-lightbox-next,
.galleria-lightbox-prev,
.galleria-lightbox-close{
  background: none;
}
.galleria-lightbox-next,
.galleria-lightbox-prev{
  color: #fdf4f1;
}
.galleria-lightbox-close{
  margin: 10px;
  color: #fdf4f1;
}
.galleria-lightbox-shadow{
  background: none;
}
/*Aktivitaeten*/
.familien{
    .blueimp-gallery{
        .title {
            position: absolute;
            top: auto;
            bottom: 30px;
            margin: 0px;
            left: 15px;
            right: 15px;
            text-align: center;
            color: #fff;
        }
    }
    #buttonDiv{
        text-align: center;
        margin-top:20px;
        margin-bottom:20px;
    }
    #links{
        padding-bottom: 20px;
    }
    #volleyballDiv{
        margin-top:20px;
    }
    .panel{
        text-align:left;
    }
    @media(min-width: 768px){
      .baerundbeschreibung{
          display:flex;
          #baerenDiv{
            display: flex;
            align-items: center;
            margin-bottom: 0;
          }
      }
    }
    #baerenDiv{
      margin-bottom: 20px;
    }
    .betont{
      font-weight: bold;
    }
}
/*Preise*/
.preise{
    .imgBox  {
        height: 600px;
        background: url(/img/auszeit.jpg) no-repeat center center fixed;
    }
    .imgText {
        text-align: center;
        margin: 0; padding: 25px 0 0 0;
    }
    .image{
        position:relative;
        z-index:1;
        text-align: center;
    }
    .trans{
        background-color:rgba(255, 255, 255, 0.8);
        border-radius:15px;
        color:black;
    }
    img{
        border-radius:15px;
    }
}
/*Kontakt*/
.kontakt{
    address{
        font-size: 18px;
    }
    img{
        width: 200px;
        margin-left: 20px;
    }
    form{
      textarea{
        height: 10em;
      }
    }
}
/*Wohnungen*/
.wohnungen{
    table{
        width:100%;
        color: $text-color;
    }
    .kategorie{
        text-align:right;
        width:50%;
        padding-right:10px;
        &.special{
          font-weight: bold;
        }
    }
    .wert{
        text-align:left;
        width:50%;
        padding-left:10px;
        &.special{
          font-weight: bold;
        }
    }
    .inner:hover{
        box-shadow: 0px 0px 3pt 2pt $gray;
    }
    a:hover{
        text-decoration:none;
    }
    .inner{
        margin-bottom: 25px;
        padding:5px;
        background-color: #f8f8f8;
        border-radius:10px;
    }
}
.wohnung{
  .centered{
    text-align: center;
    button{
      margin-bottom: 30px;
      font-size: 2em;
    }
    @media(max-width: 750px){
      button{
        font-size: 1.5em;
      }
    }
    @media(max-width: 400px){
      button{
        font-size: 1.2em;
      }
    }
  }
    h1{
        @media (max-width: 750px){
            margin-top:33px;
        }
        margin-top:0px;
    }
    .wohnungNav{
        margin-top: 20px;
        &#wohnungLinks{
        }
        &#wohnungRechts{
        }
    }
}
/*danke*/
.danke{
    img{
        border-radius: $eckenRadius+20px;
        display: inline-block;
    }
}
/*Anfahrt*/
#map{
    height:600px;
    border-radius:$eckenRadius;
    margin-bottom: 20px;
}
/*Hilfsklassen*/
.zentriert{
    text-align: center;
    img{
        display: inline-block;
    }
    &.special{
      font-weight: bold;
    }
}
.panel-title {
    font-size: $font-size-h3;
}


/* Extra Small devices (mobiles, 320px and up) */
@media (max-width: 500px) {
    h3{
        font-size: 17px;
    }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199px) {}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px){}

@media (min-width: 768px){
    .wohnungen{
        p{
            &.lead{
                font-size: 21px;
            }
            font-size: 18px;
        }
    }
}
@media (max-width: 600px){
    .startseite{
        #jumbo1{
            h1{
                font-size:40px;
            }
        }
        h1{
            font-size:30px;
        }        h2{
            font-size:20px;
        }
    }
}
